.div-opciones{
    background: rgb(255, 145, 0);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;    

    .opciones{
        margin-top: 40px;
        background-color: rgb(241, 241, 241);
        width: 150px;
        height: 150px;
        border: 2px solid green;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
            width: 95%;
        }
    }

    .opciones:hover{
        cursor: pointer;
    }
}