.div-dibujo{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .botones{
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .btn{
            margin-left: 50px;
            img{
                width: 200px;
            }
        }
        .btn:hover{
            cursor: pointer;
        }
    }
}